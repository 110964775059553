import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import CounterInput from 'react-bootstrap-counter';

import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Modal,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"


const Staking = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const current = new Date();
    var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

    var GivenDate = '26/2/2022';
    var CurrentDate = date

    console.log(CurrentDate)
    var dateCheck = (CurrentDate === GivenDate) ? "This is true" : "This is not true";

    var buttonCheck = 1;
    var mintText = "Mint"
    var mintButtons = <Button variant="primary">{mintText}</Button> ;
    var stakingAmount = "$3298423948239"

    
  return (
    <>
      <Container className={props.class} style={{position:"relative", zIndex:"0"}} >
        <Row>
          <Col xs={12} lg={6} className="text-container">
            <h1>
              {props.title}
            </h1>
          {/* <h2> {dateCheck} </h2> */}
            <h3>
                {props.subtitle}</h3>
            <p dangerouslySetInnerHTML={{ __html: props.text }}>
            
            </p>
            
{/*            
           <Button  onClick={handleShow}>
           Mint Now
          </Button>  */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            <Form>
           {/* {['checkbox'].map((type) => (
                <div key={`default-${type}`} className="mb-3">
             
                <Form.Check 
                    type={type}
                    id="1"
                    label="Lock"
                />
                <Form.Check 
                    type={type}
                    id="2"
                    label="Diamond pass"
                />


                </div>
            ))}  */}
           <Button variant="primary">
                Mint
            </Button>
            <Button variant="primary">
                Mint + Stake
            </Button> 
           
           <p><strong>Staking will get you a FREE Diamond Pass</strong></p>
        
            </Form>

            </Modal.Body>
            <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
            {/* <Button variant="primary">
                Mint now
            </Button> */}
            </Modal.Footer>
        </Modal>
            
          
          </Col>
          <Col xs={12} lg={6} className="jumbo-right-image staking-post-it">
           <Row>
               <Col xs={12}>
               <div className="staking-balance">
              <h2>Your Balance</h2>
                <p className="staking-amount">{stakingAmount}</p>
                <p className="staking-disclaimer">(Not be claimable until the game is released.)</p>
          </div>
          <div className="gatsby-image-wrapper">
          <img src={props.image} alt={props.alt} /> 
          </div>
               </Col>
           </Row>
           <Row>
               <Col xs={12}>
               <Button variant="primary">
                Claim
            </Button>
               </Col>
           </Row>
         
          
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Staking
