import * as React from "react"
import FourCols from "../../components/FourCols"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import Navigation from "../../components/Navigation"
import TwoCols from "../../components/TwoCols"
import mainImage from "../../images/main-image.png"
import roadmap1 from "../../images/roadmap1.png"
import roadmap2 from "../../images/roadmap2.png"
import roadmap3 from "../../images/roadmap3.png"
import teamMember from "../../images/team.png"
import faqMan from "../../images/faq.png"
import leftArrow from "../../images/arrow-left.png"
import rightArrow from "../../images/arrow-right.png"
import line1 from "../../images/line1.png"
import line2 from "../../images/line2.png"
import line3 from "../../images/line3.png"
import heroGif from "../../images/hero-gif.gif"
import postIt from "../../images/staking-post-it.png"
import martin from "../../images/MARTIN.png"
import monarch from "../../images/MONARCH.jpg"
import nftdyor from "../../images/NFTDYOR.png"
import flex from "../../images/FLEX.png"
import rich from "../../images/RICHARD.png"
import ekat from "../../images/EKAT.jpg"
import dkong from "../../images/DKONG.jpg"
import vikong from "../../images/VIKONG.jpg"
import ryan from "../../images/RYAN.png"
import drake from "../../images/DRAKE.png"
import katy from "../../images/KATYA.jpg"

import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"
import FAQs from "../../components/FAQs"
import DiamondHands from "../../components/DiamondHands"
import Mint from "../../components/Mint"
import Staking from "../../components/Staking"
const StakingPage = () => (
  
   <Layout>


  <Staking 
   title="Staking"
   subtitle="EARN $PPR WHEN YOU STAKE
   "
   text="Stake your PaperHands to earn $PPR passively before the game is even released! Please keep in mind you will not be able to claim it (transfer it to your wallet) until the game is released. "
   button="Mint now"
   image={postIt}
   alt="Paperhands"
   class="jumbo-section hero full-width  mobile-pt-13 minting staking"/>

<Container className="roadmap-container mobile-mt-m10 mt-m8 mobile-pt-7 pt-7 mobile-pb-10" style={{position:"relative", zIndex:"2"}}>
        <Row>
          <Col xs={12} className="text-container">
          
           
            <Tabs defaultActiveKey="staked" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="staked" title="Staked Paperhands">

  <FourCols 
        class1="team-box"
        class2="team-box"
        class3="team-box"
        class4="team-box"
        image1={nftdyor}
        image2={monarch} 
        image3={drake}
        image4={flex}
        />
       
  </Tab>
  <Tab eventKey="unstaked" title="Unstaked Paperhands">
  <FourCols
        class1="team-box"
        class2="team-box"
        class3="team-box"
        class4="team-box"
        image1={katy}
        image2={ekat}
        image3={martin}
        image4={ryan}


        />
        <FourCols 
        class1="team-box"
        class2="team-box"
        class3="team-box"
        class4="team-box"
        image1={rich}
        image3={drake}
        image4={nftdyor}
        image2={katy}

      
        
        />
  </Tab>
</Tabs>

          </Col>

        </Row>

      
 </Container>

</Layout>
)

export default StakingPage
